import{Message} from  'element-ui'
import { sleep,encryptCode,decryptCode } from "@/utils/function";
import { request_sign } from '@/utils/const';
import API from '@/api/teacher/calendar/ExamsList'
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
// 引入基本模板
import * as echarts from 'echarts';
export default {
  name: "StudentsList",
  components: {},
  inject:['reload'],
  data() {
    return {
      projectShow:"",
      projectExpEcharts:"",
      projectRadar:"",
      projectHistogram:"",
      projectOpt:"",
      projectAvgTotal:"",
      groupName:"",
      projectNameTitle:"",
      search:"" ,
      project_id:"",
      projectTotal:"",
      projectExp:"",
      projectTest:"",
      projectReport:"",
      activeName:'first',
      rightContentShow: true,
      expList:[],
      groupList:[],
      examList:[],
      student_id:0,
      eventStr:'',
      dataList:[],
      dialogVisible: false,
      loadingFlag:false,
    };
  },
  created() {
    let self = this;
    //  获取学生考试列表数据
    self.getData();
  },
  mounted() 
  {
    const self = this;
    if (self.groupList.length > 0 ) 
    {
      sleep(50).then(() => {
        self.updateCssStyle(self.groupList[0].id);
      });
    }

  },
  methods: 
  {
    /**
     * @name: 项目测试成绩图标显示
     */
    projectTestShow() 
    {
      this.projectShow = '3'
      // 第一次打开的时候开启loading
      if(this.projectTestEcharts == null) {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true;
      }
      setTimeout(()=>{
      // // 先获取Dom实例
      // let projectTest = echarts.getInstanceByDom(document.getElementById('projectTest'))
      // // 判断实例是否存在 如果不存在就创建新的实例
      // if(projectTest == null) {
      //   projectTest = echarts.init(document.getElementById('projectTest'));
      // }
      // 销毁实例
      if (echarts.getInstanceByDom(document.getElementById('projectTest'))) 
      {
        echarts.dispose(document.getElementById('projectTest'));
      }
      this.projectTestEcharts = echarts.getInstanceByDom(document.getElementById('projectTest'))
      // 判断实例是否存在 如果不存在就创建新的实例
      if(this.projectTestEcharts == null) {
        this.projectTestEcharts = echarts.init(document.getElementById('projectTest'));
      }
        // let projectTest = echarts.init(document.getElementById('projectTest'));
      let option ={
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectTest.ten_nine_number,this.projectTest.nine_eight_number,this.projectTest.eight_seven_number,this.projectTest.seven_six_number,this.projectTest.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectTest.ten_nine_number,this.projectTest.nine_eight_number,this.projectTest.eight_seven_number,this.projectTest.seven_six_number,this.projectTest.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectTestEcharts.setOption(option);
       if(this.loadingFlag) {
        // 关闭loading
        nprogressClose();
        this.$Loading.hide()
        this.loadingFlag = false;
       }
    },500)
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
      // 执行echarts自带的resize方法，让echarts图表自适应
      this.projectTestEcharts.resize();
    })
    },

    /**
     * @name: 项目操作成绩
     */
    projectOptShow() {
      this.projectShow = '4'
      // 第一次打开的时候开启loading
      if(this.projectOptEcharts == null) {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true
      }
      // echarts.dispose(document.getElementById(projectTest))
      setTimeout(()=>{
      // // 先获取Dom实例
      // let projectOpt = echarts.getInstanceByDom(document.getElementById('projectOpt'))
      // // 判断实例是否存在 如果不存在就创建新的实例
      // if(projectOpt == null) {
      //   projectOpt = echarts.init(document.getElementById('projectOpt'));
      // }

      // 销毁实例
      if (echarts.getInstanceByDom(document.getElementById('projectOpt'))) 
      {
        echarts.dispose(document.getElementById('projectOpt'));
      }

      // 先获取Dom实例
      this.projectOptEcharts = echarts.getInstanceByDom(document.getElementById('projectOpt'))
      // 判断实例是否存在 如果不存在就创建新的实例
      if(this.projectOptEcharts == null) {
        this.projectOptEcharts = echarts.init(document.getElementById('projectOpt'));
      }

        // let projectOpt = echarts.init(document.getElementById('projectOpt'));
      let option ={
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectOpt.ten_nine_number,this.projectOpt.nine_eight_number,this.projectOpt.eight_seven_number,this.projectOpt.seven_six_number,this.projectOpt.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectOpt.ten_nine_number,this.projectOpt.nine_eight_number,this.projectOpt.eight_seven_number,this.projectOpt.seven_six_number,this.projectOpt.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectOptEcharts.setOption(option);
      if(this.loadingFlag) {
        // 关闭loading
        nprogressClose();
        this.$Loading.hide()
        this.loadingFlag = false
      }
    },500)
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
      // 执行echarts自带的resize方法，让echarts图表自适应
      this.projectOptEcharts.resize();
    })
    },

    projectReportShow() {
      this.projectShow = '5'
      // 第一次打开的时候开启loading
      if(this.projectReportEcharts == null) {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true;
      }
      setTimeout(()=>{
      // // 先获取Dom实例
      // let projectReport = echarts.getInstanceByDom(document.getElementById('projectReport'))
      // // 判断实例是否存在 如果不存在就创建新的实例
      // if(projectReport == null) {
      //   projectReport = echarts.init(document.getElementById('projectReport'));
      // }

        // 销毁实例
        if (echarts.getInstanceByDom(document.getElementById('projectReport'))) 
        {
          echarts.dispose(document.getElementById('projectReport'));
        }
        // 先获取Dom实例
        this.projectReportEcharts = echarts.getInstanceByDom(document.getElementById('projectReport'))
        // 判断实例是否存在 如果不存在就创建新的实例
        if(this.projectReportEcharts == null) {
          this.projectReportEcharts = echarts.init(document.getElementById('projectReport'));
        }
        // let projectReport = echarts.init(document.getElementById('projectReport'));
      let option ={
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectReport.ten_nine_number,this.projectReport.nine_eight_number,this.projectReport.eight_seven_number,this.projectReport.seven_six_number,this.projectReport.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectReport.ten_nine_number,this.projectReport.nine_eight_number,this.projectReport.eight_seven_number,this.projectReport.seven_six_number,this.projectReport.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectReportEcharts.setOption(option);
      if(this.loadingFlag) {
         // 关闭loading
        nprogressClose();
        this.$Loading.hide()  
        this.loadingFlag = false
      }
    },500)
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
      // 执行echarts自带的resize方法，让echarts图表自适应
      this.projectReportEcharts.resize();
    })
    },
    /**
     * @name: 项目成绩柱状图
     */
    projectHis() 
    {
      // 销毁实例
      if (echarts.getInstanceByDom(document.getElementById('projectHistogram'))) 
      {
        echarts.dispose(document.getElementById('projectHistogram'));
      }
      this.projectHistogram = echarts.getInstanceByDom(document.getElementById('projectHistogram'));
      if(this.projectHistogram == null) 
      {
        this.projectHistogram = echarts.init(document.getElementById('projectHistogram'));
      }
      let option ={
        title: {
          text: '总分分析直方图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        grid: {
          right: '70',
          containLabel: true
      },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectTotal.ten_nine_number,this.projectTotal.nine_eight_number,this.projectTotal.eight_seven_number,this.projectTotal.seven_six_number,this.projectTotal.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectTotal.ten_nine_number,this.projectTotal.nine_eight_number,this.projectTotal.eight_seven_number,this.projectTotal.seven_six_number,this.projectTotal.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectHistogram.setOption(option);
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
        // 执行echarts自带的resize方法，让echarts图表自适应
        this.projectHistogram.resize();
      })
    },

    projectRad() 
    {
      // 销毁实例
      if (echarts.getInstanceByDom(document.getElementById('projectRadar'))) 
      {
        echarts.dispose(document.getElementById('projectRadar'));
      }
      // this.projectRadar = echarts.init(document.getElementById('projectRadar'));
      this.projectRadar = echarts.getInstanceByDom(document.getElementById('projectRadar'))
      if(this.projectRadar == null) 
      {
        this.projectRadar = echarts.init(document.getElementById('projectRadar'));
      }
      let option = {
        title: {
          text: '总分分析雷达图',
          left:'center'
        },
        legend: {
          bottom: '0'
        },
        tooltip: {
          trigger: 'item'
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '虚拟', max: 100 },
            { name: '测试', max: 100 },
            { name: '操作', max: 100 },
            { name: '报告', max: 100 },
            { name: '总分', max: 100 },
          ]
        },
        series: [
          {
            
            type: 'radar',
            data: [
              {
                value: [this.projectAvgTotal.exp, this.projectAvgTotal.test , this.projectAvgTotal.opt, this.projectAvgTotal.report, this.projectAvgTotal.total],
                name: '平均分/满分',
                areaStyle: {
                  color: '#C3DFED'
                }
              },
            ]
          }
        ]
      };
      option && this.projectRadar.setOption(option);
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
        // 执行echarts自带的resize方法，让echarts图表自适应
        this.projectRadar.resize();
      })
      
    }, 

    /**
     * @name: 项目虚拟化成绩柱状图
     */
    projectExpHis() 
    {
      // 先获取Dom实例
      // let projectExp = echarts.getInstanceByDom(document.getElementById('projectExp'))
      // // 判断实例是否存在 如果不存在就创建新的实例
      // if(projectExp == null) {
      //   projectExp = echarts.init(document.getElementById('projectExp'));
      // }

      // 开启loading
      nprogressStart();
      this.$Loading.show();

      // 销毁实例
      if (echarts.getInstanceByDom(document.getElementById('projectExp'))) 
      {
        echarts.dispose(document.getElementById('projectExp'));
      }

      this.projectExpEcharts = echarts.getInstanceByDom(document.getElementById('projectExp'))
      if(this.projectExpEcharts == null) {
        this.projectExpEcharts = echarts.init(document.getElementById('projectExp'));
      }

      let option ={
        title: {
          text: '总分分布统计图',
          left:'center'
        },
        xAxis: {
          data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
          name:'分数段(%)'
        },
        yAxis: {
          type: 'value',
          name:'人数',

        },
        legend: {
          bottom: '5%'
        },
        series: [
          {
            name:'人数',
            emphasis: {
              focus: 'series'
            },
            zlevel: 2,
            data: [this.projectExp.ten_nine_number,this.projectExp.nine_eight_number,this.projectExp.eight_seven_number,this.projectExp.seven_six_number,this.projectExp.zero_six_number],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top'
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [10, 10, 10, 10],
                color:'#3E95B5',
                opacity:.9,
              },
            }, 
            barWidth: 20,
          },
          {
            name:"曲线",
            emphasis: {
              focus: 'series'
            },
            zlevel: 1,
            data: [this.projectExp.ten_nine_number,this.projectExp.nine_eight_number,this.projectExp.eight_seven_number,this.projectExp.seven_six_number,this.projectExp.zero_six_number] ,
            type:'line',
            smooth: true,
            lineStyle:{
              color:'#0D5570'
            },
            showSymbol: false,//去除圆点
            areaStyle: {},
            areaStyle: {
                color: '#C4DFE9'
              }
          }
        ],
      }
      option && this.projectExpEcharts.setOption(option);
      // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
      window.addEventListener("resize", () => {
        // 执行echarts自带的resize方法，让echarts图表自适应
        this.projectExpEcharts.resize();
      })
      nprogressClose();
      this.$Loading.hide();
    },

    projectExpShow() {
      this.projectShow = '2'
      // 第一次打开的时候开启loading
      if(this.projectExpEcharts == null) 
      {
        // 开启loading
        nprogressStart();
        this.$Loading.show()
        this.loadingFlag = true;
      }
      // 调用项目虚拟成绩的方法
      setTimeout(()=>{
          this.projectExpHis()
          if(this.loadingFlag) 
          {
            // 关闭loading
            nprogressClose();
            this.$Loading.hide();
            this.loadingFlag = false;
          }
      },500)
    },

    updateCssStyle(id) {
      // css效果
      var d = document.querySelectorAll(".left-module tr");
      for (var p = d.length; p--;) 
      {
        if (d[p].id != id) 
        {
          d[p].style.backgroundColor = "#FFFFFF"; /*其他班级*/
          d[p].classList.remove("selectTr");
        } 
        else 
        {
          d[p].style.backgroundColor = "#E5ECF1"; /*点击的班级*/
          d[p].classList.add("selectTr");
        }
      }
    },
    getStatData()
    {
      const self = this;
      let data = {
        project_id:self.project_id
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      self.axios.post('/index.php/backStatistics/getStatData', data).then(result => {
        console.log(result)
        if(result.data.code > 0)
        {
          // console.log(res);
          self.projectTotal = result.data.total;
          self.projectExp = result.data.exp;
          self.projectReport = result.data.report;
          self.projectOpt = result.data.opt;
          self.projectTest = result.data.test;
          self.projectAvgTotal = result.data.avgTotal;
          self.groupName=result.groupName;
          self.projectNameTitle = result.data.projectName;
          self.handleClick({name:self.activeName}) 
        }
        else
        {
          self.$message({
            message: result.data.msg,
            type: 'error'
          });
        }
        nprogressClose();
        self.$Loading.hide();
      });
    },
    getcurrentActive(id)
    {
      let self = this;
      self.project_id = id;
      sleep(50).then(() => {
        self.updateCssStyle(self.project_id);
      });
      self.getStatData();
    },
    handleClick(tab) 
    {
        let self = this;
        self.activeName = tab.name;
        if(self.activeName == 'first')
        {
          setTimeout(() => {
            this.projectHis();
            this.projectRad();
          }, 100)
        }
        else if(self.activeName == 'second')
        {
          // self.projectExpHis();
          self.projectExpShow();
        }
        else if(self.activeName == 'three')
        {
          self.projectTestShow();
        }
        else if(self.activeName == 'four')
        {
          self.projectOptShow();
        }
        else
        {
          self.projectReportShow();
        }
    },
    getSearchData()
    {
      const self = this;
      self.getData();
    },
    getData()
    {
      const self = this;
      let data = {
        "search":self.search
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      self.axios.post('/index.php/backother/getFenXiProjectList', data).then(result => {
        console.log(result)
        if (result.data.code > 0) 
        {
          self.groupList = result.data.projectList;
          if(result.data.projectList.length > 0)
          {
            self.project_id = result.data.projectList[0].id;
            if(self.project_id != "" || self.project_id != 0 || self.project_id != null || self.project_id != undefined)
            {
              if (self.groupList.length > 0 ) 
              {
                sleep(50).then(() => {
                  self.updateCssStyle(self.groupList[0].id);
                });
              }
              self.getStatData();
            }
          }
        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
      });
    },
    
  },
};















